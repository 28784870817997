<template>
	<NuxtImg
		:blurhash="blurhash"
		:src="src"
		:sizes="sizes"
		:fetchpriority="props.priority ? 'high' : 'auto'"
		decoding="async"
		density="x1 x2"
		:quality="props.priority ? 75 : 60"
		format="auto"
		:modifiers="{ threshold: 10 }"
		preload
		:loading="props.priority ? 'eager' : 'lazy'"
		:alt="props.image?.alt"
		:style="[hotspotStyle, aspectRatioStyle]"
		:class="[orientation, `object-${props.object}`, {'opacity-0': isLoading}]"
		class="flex-img transition-opacity duration-300"
		@load="isLoading = false"
		provider="sanity"
		auto-sizes
	/>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { ImageType } from '~~/types'
import type { ImageUrlFormat, ImageUrlFitMode, ImageAsset } from "@sanity/types";

const isLoading = ref(true)

const props = defineProps({
	image: {
		type: Object as PropType<ImageType>,
		default: () => undefined,
	},
	fit: {
		default: 'max',
		type: String as PropType<ImageUrlFitMode>,
	},
	useHotspot: {
		type: Boolean,
		default: () => false,
	},
	object: {
		type: String as PropType<"cover" | "contain" | "fill" | "none" | "scale-down">,
		default: "cover",
	},
	sizes: {
		type: String,
		default: '100vw md:800px lg:1000px xl:1600px',
	},
	priority: {
		type: Boolean,
		default: false
	},
	width: {
		type: Number,
		default: 1024,
	},
	height: {
		type: Number,
		default: 768,
	},
})

const blurhash = computed(() => props.image?.asset?.metadata?.blurHash ?? '')

const src = computed<string>(() => props.image?.asset?._ref as string)

const cropWidth = computed(() => {
	const originalWidth = props.image?.asset?.metadata?.dimensions?.width ?? 1000
	const cropLeft = props.image?.crop?.left ?? 0
	const cropRight = props.image?.crop?.right ?? 0
	return (originalWidth - cropLeft * originalWidth - cropRight * originalWidth)
})

const cropHeight = computed(() => {
	const originalHeight = props.image?.asset?.metadata?.dimensions?.height ?? 768
	const cropTop = props.image?.crop?.top ?? 0
	const cropBottom = props.image?.crop?.bottom ?? 0
	return (
		originalHeight - cropTop * originalHeight - cropBottom * originalHeight
	)
})

const orientation = computed(() => {
	const originalWidth = props.image?.asset?.metadata?.dimensions?.width
	const originalHeight = props.image?.asset?.metadata?.dimensions?.height
	if (!originalWidth || !originalHeight) return undefined
	const orientation = originalWidth > originalHeight
		? 'landscape'
		: originalWidth < originalHeight
		? 'portrait'
		: 'square'
	return orientation
})

/* use the hotspot values defined in Sanity to align the image inside the element box. 
Most useful if the image should fill its container (with "object-fit: cover")  */
const hotspotStyle = computed(() => {
	return props.useHotspot && props.image?.hotspot && props.image?.hotspot.x !== undefined
		? {
				'object-position': `${[
					props.image?.hotspot.x,
					props.image?.hotspot.y,
				]
					.map((value: number) => `${(value * 100).toFixed(2)}%`)
					.join(' ')}`,
		  }
			: ''
})

/* is helpful to avoid wrong aspect ratio when the blurry placeholder is visible */
const aspectRatioStyle = computed(() => {
	return !props.useHotspot
		? {
				aspectRatio: `${cropWidth.value} / ${cropHeight.value}`,
		  }
		: {}
})
</script>

<style scoped>
picture,
picture:deep(img) {
	display: block;
}
</style>