<script setup lang="ts">
const props = defineProps({
  display: {
    type: String, // outlined, filled, flat
    default: 'filled',
  },
  label: {
    type: String,
    default: '',
  },
  internalLink: {
    type: Object,
    default: () => {},
  },
  slug: {
    type: String,
    default: '',
  },
})

const url = computed(() => {
  let slug =
    props.internalLink &&
    props.internalLink.meta &&
    props.internalLink.meta.slug
      ? props.internalLink.meta.slug
      : ''
  if (slug.length && slug.slice(0, 1) !== '/') {
    slug = `/${slug}`
  }
  return slug
})

const title = computed(() =>
  props.internalLink && props.internalLink.meta && props.internalLink.meta.title
    ? props.internalLink.meta.title
    : '',
)
</script>

<template>
  <div class="relative flex">
    <UButton
      :to="`${url}`"
      :alt="title"
      variant="outline"
      size="xl"
      :class="`button-${display}`"
    >
      <slot>{{ label }}</slot>
    </UButton>
  </div>
</template>
