<script setup lang="ts">
const props = defineProps({
  linkType: {
    type: String,
    default: () => "externalLink",
  },
  href: {
    type: String,
    default: () => "",
  },
  route: {
    type: String,
    default: () => "",
  },
  slug: {
    type: String,
    default: () => "",
  },
  newtab: {
    type: Boolean,
    default: () => true,
  },
})
const internalRoute = computed(() => {
  return {
    name: props.route ?? "index",
    params: props.slug ? { slug: `/${props.slug}` } : {},
  }
})

const url = computed(() => {
  let slug = props.slug
  if (slug.length && slug.slice(0, 1) !== "/") {
    slug = `/${slug}`
  }
  return slug
})
</script>

<template>
  <NuxtLink
    :to="props.linkType === 'externalLink' ? props.href : url"
    :target="props.newtab && props.linkType === 'externalLink' ? '_blank' : ''"
    :rel="props.newtab && props.linkType === 'externalLink' ? 'noopener' : ''"
    class="link"
    ><slot></slot
  ></NuxtLink>
</template>
