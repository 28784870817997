<script setup lang="ts">
const props = defineProps({
  style: {
    type: String,
    default: () => '',
  },
  children: {
    type: Object,
    default: () => {},
  },
})
</script>
<template>
  <h2
    v-if="props.style === 'h1'"
    class="text-5xl md:text-6xl lg:text-7xl pb-8 font-serif text-burgundy"
  >
    <slot />
  </h2>
  <h2
    v-else-if="props.style === 'h2'"
    class="text-4xl md:text-5xl lg:text-6xl pt-3 pb-6 text-burgundy font-serif"
  >
    <slot />
  </h2>
  <div
    v-else-if="props.style === 'h3'"
    class="text-3xl lg:text-4xl font-medium pt-10 pb-3 leading-tight tracking-wide font-serif text-burgundy"
  >
    <slot />
  </div>
  <div
    v-else-if="props.style === 'h4'"
    class="text-xl lg:text-xl font-semibold text-gray-900"
  >
    <slot />
  </div>
  <p
    v-else-if="props.style === 'p'"
    class="max-w-2xl mx-auto leading-loose"
  >
    <slot />
  </p>
  <strong
    v-else-if="props.style === 'b' || props.style === 'strong'"
    class="max-w-2xl mx-auto mb-4 leading-loose"
  >
    <slot />
  </strong>
  <p
    v-else-if="props.style === 'small'"
    class="text-sm"
  >
    <slot />
  </p>
  <p
    v-else
    class="block mb-6"
  >
    <slot />
  </p>
</template>
