<script setup>
const props = defineProps({
  slug: {
    type: String,
    default: () => "",
  },
  type: {
    type: String,
    default: () => "",
  },
});
const slug = computed(() =>
  props.type === "post" && !props.slug.includes("blog/")
    ? `blog/${props.slug}`
    : props.slug
);
</script>
<template>
  <ULink :to="`/${slug}`">
    <slot /> <UIcon name="heroicons-arrow-up-right-16-solid" />
  </ULink>
  <!-- <NuxtLink :to="`/${slug}`"
    ><span class="inline-flex items-center space-x-2"
      ><slot /><IconsArrowUpRight /></span
  ></NuxtLink> -->
</template>
