<template>
	<figure class="w-full h-full">
		<MediaBaseImage
			:image="props.image"
			:auto="props.auto"
			:fit="props.fit"
			:object="props.object"
			:use-hotspot="props.useHotspot"
			:default-width="props.defaultWidth"
		/>
		<figcaption v-if="props.image?.credit" class="text-sm">
			{{ props.image.credit }}
		</figcaption>
	</figure>
</template>

<script setup lang="ts">
import type { ImageUrlFormat, ImageUrlFitMode } from "@sanity/types";
import type { ImageType } from "~~/types";
const props = defineProps({
	image: Object as PropType<ImageType>,
	auto: String as PropType<ImageUrlFormat>,	
	fit: String as PropType<ImageUrlFitMode>,
	useHotspot: Boolean,
	object: String as PropType<"cover" | "contain" | "fill" | "none" | "scale-down">,
	defaultWidth: Number,
	sizes: String,
	priority: Boolean
})
</script>