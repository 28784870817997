<script setup lang="ts">
// import BaseFigure from "@/components/media/BaseFigure"
// import InlineImage from "@/components/media/InlineImage"
import Link from "./ElementLink.vue"
import InternalLink from "./ElementInternalLink.vue"
import Element from "./Element.vue"
import type { PortableTextBlock, PortableTextMarkDefinition, PortableTextSpan, ArbitraryTypedObject } from '@portabletext/types'
import type { PropType } from 'vue'
import Button from "./ElementButton.vue"
// import Gallery from "@/components/Gallery"
const props = defineProps({
  blocks: {
    type: Array as PropType<PortableTextBlock<PortableTextMarkDefinition, ArbitraryTypedObject | PortableTextSpan, string, string>[]>,
    default: () => [],
  },
})
const serializers = {
  marks: {
    link: Link,
    internalLink: InternalLink,
    underline: "u",
    "strike-through": "s",
  },
  types: {
    // image: BaseImage,
    // img: InlineImage,
    button: Button,
    // gallery: Gallery,
  },
  styles: {
    normal: Element,
    h1: Element,
    h2: Element,
    h3: Element,
    h4: Element,
    small: Element,
  },
}
</script>

<template>
  <div class="sanity-blocks">
    <SanityContent :blocks="props.blocks" :serializers="serializers" />
  </div>
</template>

<style>
.sanity-blocks p {
  white-space: break-spaces;
}
.sanity-blocks ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-bottom: 16px;
}
.sanity-blocks ol {
  list-style-type: decimal;
  list-style-position: inside;
}
.sanity-blocks ul ul,
.sanity-blocks ol ul {
  list-style-type: circle;
  list-style-position: inside;
  /* margin-left: 15px; */
}
.sanity-blocks ol ol,
.sanity-blocks ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  /* margin-left: 15px; */
}
.sanity-blocks li {
  padding-top: 6px;
  padding-bottom: 6px;
}
</style>
